import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function OneBlog() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://spira-nature.com/api/blog/get/${id}`); // Adjust the URL to your actual endpoint
        setBlog(response.data);
      } catch (error) {
        console.error('Error fetching blog:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (!blog) {
    return <div>Blog introuvable</div>;
  }

  return (
    <div className="flex flex-col justify-center items-center gap-8 p-4 sm:p-8">
      <div className="flex flex-col sm:flex-row w-full sm:w-auto">
        <img
          src={JSON.parse(blog.images)[0]} // Assuming images is an array and displaying the first image
          alt={blog.title}
          className="w-full sm:w-[60vh] h-[30vh] sm:h-[60vh] rounded-t-lg sm:rounded-l-lg sm:rounded-tr-none object-cover mb-4 sm:mb-0 sm:mr-4"
        />
        <div className="flex flex-col justify-between gap-4">
          {JSON.parse(blog.images).slice(1, 3).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={blog.title}
              className="w-full sm:w-[30vh] h-[20vh] sm:h-[29vh] object-cover"
              style={{
                borderTopRightRadius: index === 0 ? '0.5rem' : '0',
                borderBottomRightRadius: index === 1 ? '0.5rem' : '0'
              }}
            />
          ))}
        </div>
      </div>
      <h1 className="text-2xl sm:text-3xl font-bold text-[#0072a4] text-center">{blog.title}</h1>
      <p className="text-xs sm:text-sm text-gray-500 text-center">Date: {new Date(blog.createdAt).toLocaleDateString()}</p>
      <hr className="border border-slate-400 w-full sm:w-[120vh]" />
      <p className="text-gray-700 text-center sm:text-left">{blog.description}</p>
    </div>
  );
}

export default OneBlog;
