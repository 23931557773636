import React, { useState, useEffect } from 'react';
import Login from './Login';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Register() {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(true);
  const [data, setData] = useState({
    name: "",
    prenom: "",
    email: "",
    telephone: "",
    adresse: "",
    password: ""
  });

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/')
    }
  }, []);

  const register = () => {
    axios.post('https://spira-nature.com/api/user/register', data)
      .then(r => {
        setIsSignUp(!isSignUp)
      }).catch(err => alert(err.response.data))
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    });
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white p-6 rounded shadow-lg">
        <div className="flex justify-center items-center w-full border border-1 rounded p-2 mb-4">
          <h1
            className={`flex-1 text-center cursor-pointer ${!isSignUp ? 'bg-black text-white border rounded p-2' : ''}`}
            onClick={() => setIsSignUp(false)}
          >
            Se connecter
          </h1>
          <h1
            className={`flex-1 text-center cursor-pointer ${isSignUp ? 'bg-black text-white border rounded p-2' : ''}`}
            onClick={() => setIsSignUp(true)}
          >
            S'inscrire
          </h1>
        </div>

        {isSignUp ? (
          <div className="space-y-4">
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <input
                onChange={handleOnChange}
                type="text"
                name='name'
                placeholder="Prénom"
                className="flex-1 p-2 border border-gray-300 rounded"
              />
              <input
                onChange={handleOnChange}
                type="text"
                name='prenom'
                placeholder="Nom de famille"
                className="flex-1 p-2 border w-[150px] border-gray-300 rounded"
              />
            </div>
            <input
              onChange={handleOnChange}
              type="email"
              name='email'
              placeholder="Email"
              className="w-full p-2 border border-gray-300 rounded"
            />
            <input
              onChange={handleOnChange}
              type="password"
              name='password'
              placeholder="Mot de passe"
              className="w-full p-2 border border-gray-300 rounded"
            />
            <div onClick={() => {
              register()
            }} className="w-full bg-black flex justify-center items-center cursor-pointer text-white p-2 rounded">S'inscrire</div>
          </div>
        ) : (
          <Login />
        )}

        {isSignUp && (
          <>
            <hr className="my-4" />
            
          </>
        )}
      </div>
    </div>
  );
}

export default Register;
