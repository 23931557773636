import React, { useState } from 'react';
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";

function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className='p-4 md:p-8'>
      <div className='flex flex-col bg-[#fef9f3] w-full h-full border-transparent rounded-lg gap-6 md:gap-12 px-4 md:px-24 py-6 md:py-12'>
        <h1 className='text-4xl md:text-6xl'>FAQs</h1>
        <div>
          <h3 className='text-lg md:text-xl'>Questions les plus fréquemment posées</h3>
          <hr />
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row justify-between items-center'>
            <h1 className='font-bold text-lg md:text-xl'>
              1. Quels sont les modes de paiement acceptés sur notre site ?
            </h1>
            <div onClick={() => toggleExpand(0)}>
              {expandedIndex === 0 ? <CiCircleMinus size={32} /> : <CiCirclePlus size={32} />}
            </div>
          </div>
          {expandedIndex === 0 && (
            <p className='text-sm md:text-base'>Sur notre site, nous acceptons les paiements par carte bancaire en ligne, que ce soit des cartes DB, Visa, Mastercard et American Express. De plus, nous offrons également la possibilité de payer à la livraison pour plus de commodité.</p>
          )}
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row justify-between items-center'>
            <h1 className='font-bold text-lg md:text-xl'>
              2. Quels sont les délais de livraison ?
            </h1>
            <div onClick={() => toggleExpand(1)}>
              {expandedIndex === 1 ? <CiCircleMinus size={32} /> : <CiCirclePlus size={32} />}
            </div>
          </div>
          {expandedIndex === 1 && (
            <p className='text-sm md:text-base'>Nous faisons tout notre possible pour que vous receviez votre commande dans un délai de 48 à 72 heures après son passage, hors week-ends.</p>
          )}
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row justify-between items-center'>
            <h1 className='font-bold text-lg md:text-xl'>
              3. Puis-je retourner un produit en cas de problème ?
            </h1>
            <div onClick={() => toggleExpand(2)}>
              {expandedIndex === 2 ? <CiCircleMinus size={32} /> : <CiCirclePlus size={32} />}
            </div>
          </div>
          {expandedIndex === 2 && (
            <p className='text-sm md:text-base'>Oui, vous pouvez retourner un produit s'il présente un problème. Contactez-nous pour organiser le retour.</p>
          )}
        </div>
        <div className='flex flex-col gap-4'>
          <h1 className='font-bold text-lg md:text-xl'>
            Besoin d'aide supplémentaire ?
          </h1>
          <p className='text-sm md:text-base'>Si vous avez d'autres questions ou besoin d'assistance supplémentaire, n'hésitez pas à nous contacter. Vous pouvez nous contacter par téléphone 98 744 600, ou nous rendre visite à notre boutique située à 65 Avenue Ibn Kholdoun, Ariana, Tunisia.</p>
        </div>
        <div className='flex justify-center mb-2 text-center text-sm md:text-base'>Nous vous remercions chaleureusement pour votre confiance et votre soutien continu.</div>
      </div>
    </div>
  );
}

export default FAQ;
