import React from 'react'

function Delivery() {
  return (
    <div className='flex flex-col md:flex-row justify-center items-center gap-8 md:gap-36 bg-[#fef9f3] shadow p-8'>
      <div className='flex flex-col justify-center items-center mb-8 md:mb-0'>
        <img className='rounded-full w-24 md:w-36' src="https://th.bing.com/th/id/R.0f21b0eb9bd34af235721f2e06ab3088?rik=L18HsusjKP%2brDA&riu=http%3a%2f%2ffreevector.co%2fwp-content%2fuploads%2f2009%2f08%2f82018-fast-delivery.png&ehk=acDjvxv9mnVVwqIeOKmJXNYpHh46XdqFNtW%2fvs7Dcec%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1" alt="Free and Fast Delivery" />
        <h1 className='font-bold text-lg text-center'>LIVRAISON RAPIDE</h1>
        
      </div>
      <div className='flex flex-col justify-center items-center gap-4 md:gap-12 mb-8 md:mb-0'>
        <img className='rounded-full w-12 md:w-20 mt-4 md:mt-6' src="https://static.thenounproject.com/png/4074783-200.png" alt="24/7 Customer Service" />
        <div>
          <h1 className='font-bold text-lg text-center'>SERVICE CLIENT 24h/24 et 7j/7</h1>
          <h3 className='text-center'>Support client convivial 24h/24 et 7j/7</h3>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center gap-4 md:gap-12'>
        <img className='rounded-full w-12 md:w-20 mt-4 md:mt-6' src="https://cdn-icons-png.flaticon.com/512/95/95454.png" alt="Secure Payment" />
        <div>
          <h1 className='font-bold text-lg text-center'>PAIEMENT SÉCURISÉ</h1>
          <h3 className='text-center'>Nous garantissons un paiement sécurisé</h3>
        </div>
      </div>
    </div>
  )
}

export default Delivery
