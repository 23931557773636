import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Review from './Review';
import { useNavigate, useParams } from 'react-router-dom';
import { ObjContext } from '../context';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
const ProductDetail = () => {
  const [user, setUser] = useState([]);
  const token = localStorage.getItem('token');
  const { load, setLoad } = useContext(ObjContext);
  const [currentImage, setCurrentImage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [allProd, setAllProd] = useState([]); 
  const [heart, setHeart] = useState(false);
  const [index, setIndex] = useState(null);
  const relatedProducts = allProd.filter(p => p.category === product?.category && p.idpoducts !== product.idpoducts);
  useEffect(() => {
    axios.post('https://spira-nature.com/api/user/user-details', { token })
      .then(r => setUser(r.data))
      .catch(err => console.log(err));
  }, []);
  useEffect(() => {
    axios.get('https://spira-nature.com/api/product/getAllProd')
      .then(response => {
        setAllProd(response.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    axios.get(`https://spira-nature.com/api/product/get/${id}`)
      .then(response => {
        setProduct(response.data);
        setCurrentImage(JSON.parse(response.data.images)[0]); // Set the initial main image
      })
      .catch(error => {
        alert('');
        navigate('/details/' + id);
      });
  }, [id, navigate]);

  const addToCart = (p) => {
    setLoad(!load);
    if (!localStorage.getItem('token')) {
      navigate('/register');
    }
    axios.post('https://spira-nature.com/api/cart/add/', p)
      .then(r => console.log(r))
      .catch(err => {
        alert('Essayer une autre fois');
        navigate('/details/' + id);
      });
  };
  const addToWishlist = (productId) => {
    const token=localStorage.getItem('token')
    if (!token){
      navigate('/register')
    }
    const p = { productID: productId, userID: user.idusers };
    axios.post('https://spira-nature.com/api/wish/add', p)
      .catch(err => {
        alert('Le Produit Est Deja Ajouté')
        navigate('/')
      });
  };
  if (!product) {
    return <div>Chargement...</div>;
  }

  return (
    <div className="flex flex-col gap-32 p-4 md:p-12">
      <div className="flex flex-col md:flex-row justify-center gap-6 md:gap-12">
        <div className="flex flex-row md:flex-col gap-[20px]">
          {JSON.parse(product.images).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className="w-20 h-20 md:w-32 md:h-32 border-2 border-gray-200 cursor-pointer"
              onMouseEnter={() => setCurrentImage(image)}
            />
          ))}
        </div>
        <img src={currentImage} alt="Main" className="w-full md:w-[96vh] h-auto md:h-[70vh] border rounded-lg" />
        <div className="flex flex-col gap-4 w-full md:w-1/2 bg-[#fef9f3] p-10 rounded-lg">
          <h1 className="text-2xl md:text-3xl font-bold">{product.name}</h1>
          <p className="text-lg md:text-xl font-semibold text-[#0072a4]">{product?.sale ? product.price - ((product.price * product.sale) / 100) : product?.price} DT</p>
          <p className="break-words text-gray-600">{product.description}</p>
          <div className="flex flex-row items-center gap-2">
            <div 
              onClick={() => addToCart({ ProductID: product.idpoducts, userID: user.idusers })}
              className="bg-[#0072a4] cursor-pointer text-white px-4 py-2 rounded-lg"
            >
              Ajouter Au Panier
            </div>
          </div>
        </div>
      </div>
      <Review productId={id} user={user} />
      <div className="mt-8">
        <h2 className="text-lg md:text-xl font-bold mb-4">Produits connexes</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {relatedProducts.map((relatedProduct, i) => (
            <div
              key={relatedProduct?.idpoducts}
              className="bg-[#fef9f3] p-6 rounded-lg overflow-hidden w-[300px] relative cursor-pointer"
              onClick={() => navigate(`/details/${relatedProduct.idpoducts}`)}
            >
              <img src={JSON.parse(relatedProduct.images)[0]} alt={relatedProduct.name} className="w-full h-[250px] object-cover rounded-t-lg" />
              <div className="px-4 py-2">
                <h3 className="text-xl font-semibold mb-2">{relatedProduct?.name}</h3>
                <p className="text-sm text-gray-600 truncate mb-2">{relatedProduct?.description}</p>
                {relatedProduct?.sale ? (
                  <div className='flex gap-[10px]'>
                    <h1 className='text-[#0072a4]'>
                      {relatedProduct?.price - ((relatedProduct?.price * relatedProduct?.sale) / 100)} DT
                    </h1>
                    <h1 className='line-through'>{relatedProduct?.price} DT</h1>
                  </div>
                ) : (
                  <h1 className='text-[#0072a4]'>{relatedProduct?.price} DT</h1>
                )}
                <div className='flex flex-row gap-2 justify-end'>
                  <AiOutlineShoppingCart
                    className="cursor-pointer text-[#0072a4]"
                    size={30}
                    onClick={(e) => {
                      e.stopPropagation();
                      addToCart({ ProductID: relatedProduct.idpoducts, userID: user.idusers });
                    }}
                  />
                  {!heart || index !== relatedProduct.idpoducts ? (
                    <FaRegHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        setIndex(relatedProduct.idpoducts);
                        setHeart(true);
                        addToWishlist(relatedProduct.idpoducts);
                      }}
                      className='cursor-pointer text-[#0072a4]'
                      size={30}
                    />
                  ) : (
                    index === relatedProduct.idpoducts && <FaHeart size={30} color='#3b82f6' />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
