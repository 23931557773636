import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AmountContext } from '../context1'
import { ObjContext } from '../context'
import axios from 'axios'

const Succes = () => {
  const  formid  = localStorage.getItem("sessionid")
  const  obj  = JSON.parse(localStorage.getItem('prodobj'))

  useEffect(() => {
    const postOrders = async () => {
      
        try {
          await Promise.all(
            obj.map(e => 
              axios.post('https://spira-nature.com/api/order/post', {
                userID: null,
                amount: e.quantity,
                productID: e.id,
                formID: formid
              })
            )
          )
          console.log('All orders posted successfully')
        } catch (error) {
          console.error('Error posting orders:', error)
  
      }
    }

    const timeoutId = setTimeout(() => {
      postOrders()
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div className="flex justify-center items-center min-h-screen bg-green-100">
      <div className="text-center bg-white p-6 border border-blue-200 rounded-md shadow-md">
        <h1 className="text-2xl font-bold text-blue-700 mb-4">Succès</h1>
        <p className="text-blue-700 mb-2">Votre paiement a été envoyé avec succès</p>
        <Link to="/">
          <button className="button mt-4"> 
            Retour à la page d’accueil
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Succes
