import React, { useState } from 'react'
import './brands.css'
import Description from './Description'
import Category from './Category'
import ProductDisplay from '../ProductsDisplay'
const Products = () => {
    const[animation,setAnimation]=useState("https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg")
    const[anim,setAnim]=useState("")
    // const handleAnimation=()=>{
    //     setAnim('fade-out');
    //     setTimeout(() => {
    //       setAnimation((prev)=>"https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg" ? "https://i.pinimg.com/originals/6e/6b/6c/6e6b6c26b0f78e7bc04b56568b24a1b0.gif" :"https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg");
    //       setAnim('fade-in');
    //     }, 300); 
    // }
    return (
    // <div className='w-full h-full'>
    //     <h1 className='text-center font-semibold -tracking-tighter'>DISCOVER HAIRCARE FOR ALL</h1>
    //     <div className='bg-[black] mx-auto mt-[20px] flex  h-[1px] w-[150px]'>
            
    //     </div>
    //     <div className='w-full h-max grid grid-cols-[20%,20%,20%,20%] gap-[5%] justify-evenly mt-[30px]'>
           
           
           
    //         <div
    //         onMouseLeave={()=>setAnimation("https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg")}
    //         onMouseEnter={()=>setAnimation("https://i.pinimg.com/originals/6e/6b/6c/6e6b6c26b0f78e7bc04b56568b24a1b0.gif")}
    //         className={`w-full grid grid-rows-[fit,fit]  rounded-xl  `}>
    //         <img className={` rounded-t-xl w-fit h-[40vh]`}  src={animation} alt="" />
    //         <div className={` hover:bg-black mx-auto `}>
    //         <h1 className='font-semibold -tracking-tighter text-center'>HAIRCARE</h1>
    //         <h1 className='font-semibold text-[20px] -tracking-tighter text-center'>Custon Shampo</h1>
    //         <h1 className='font-sm -tracking-tighter text-center text-gray-500'>Starting at $23.00</h1>
    //         </div>
    //         </div>
    //         <div
    //         onMouseLeave={()=>setAnimation("https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg")}
    //         onMouseEnter={()=>setAnimation("https://i.pinimg.com/originals/6e/6b/6c/6e6b6c26b0f78e7bc04b56568b24a1b0.gif")}
    //         className={`w-full grid grid-rows-[fit,fit]  rounded-xl  `}>
    //         <img className={` rounded-t-xl w-fit h-[40vh]`}  src={animation} alt="" />
    //         <div className={` hover:bg-black mx-auto `}>
    //         <h1 className='font-semibold -tracking-tighter text-center'>HAIRCARE</h1>
    //         <h1 className='font-semibold text-[20px] -tracking-tighter text-center'>Custon Shampo</h1>
    //         <h1 className='font-sm -tracking-tighter text-center text-gray-500'>Starting at $23.00</h1>
    //         </div>
    //         </div>
    //         <div
    //         onMouseLeave={()=>setAnimation("https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg")}
    //         onMouseEnter={()=>setAnimation("https://i.pinimg.com/originals/6e/6b/6c/6e6b6c26b0f78e7bc04b56568b24a1b0.gif")}
    //         className={`w-full grid grid-rows-[fit,fit]  rounded-xl  `}>
    //         <img className={` rounded-t-xl w-fit h-[40vh]`}  src={animation} alt="" />
    //         <div className={` hover:bg-black mx-auto `}>
    //         <h1 className='font-semibold -tracking-tighter text-center'>HAIRCARE</h1>
    //         <h1 className='font-semibold text-[20px] -tracking-tighter text-center'>Custon Shampo</h1>
    //         <h1 className='font-sm -tracking-tighter text-center text-gray-500'>Starting at $23.00</h1>
    //         </div>
    //         </div>
    //         <div
    //         onMouseLeave={()=>setAnimation("https://paraclic.tn/15964-home_default/jouvence-kerabio-apres-shampoing-200-ml.jpg")}
    //         onMouseEnter={()=>setAnimation("https://i.pinimg.com/originals/6e/6b/6c/6e6b6c26b0f78e7bc04b56568b24a1b0.gif")}
    //         className={`w-full grid grid-rows-[fit,fit]  rounded-xl  `}>
    //         <img className={` rounded-t-xl w-fit h-[40vh]`}  src={animation} alt="" />
    //         <div className={` hover:bg-black mx-auto `}>
    //         <h1 className='font-semibold -tracking-tighter text-center'>HAIRCARE</h1>
    //         <h1 className='font-semibold text-[20px] -tracking-tighter text-center'>Custon Shampo</h1>
    //         <h1 className='font-sm -tracking-tighter text-center text-gray-500'>Starting at $23.00</h1>
    //         </div>
    //         </div>
        
        
           
           
    //     </div>
    //     <div className='flex justify-center mt-[30px]'>
    //     <button className='  w-[20%] h-[40px] rounded-xl text-[14px] text-center justify-center hover:text-white hover:bg-black hover:transition-all bg-white border-black border-[1px] text-black tracking-widest'>SHOP ALL </button>
        
    //     </div>
    //     <Description/> 
    //     <Category/>
    // </div>
    <div>
        <ProductDisplay/>
    </div>
  )
}

export default Products