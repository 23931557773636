import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdDeleteOutline } from "react-icons/md";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [load1, setLoad1] = useState(false);

  const del = (id) => {
    axios.delete('https://spira-nature.com/api/payment/del/' + id).then(r =>
      axios.delete('https://spira-nature.com/api/order/del/' + id).then(r => {
        setLoad1(!load1)
      })
      .catch(r => console.log(r))
    )
    .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchClients();
  }, [load1]);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://spira-nature.com/api/payment/get');
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchOrderDetails = async (idformulaire) => {
    try {
      const response = await axios.get(`https://spira-nature.com/api/order/get/${idformulaire}`);
      setOrderDetails(response.data);
      setPopupVisible(true);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const handleRowClick = (idformulaire) => {
    fetchOrderDetails(idformulaire);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setOrderDetails(null);
  };

  const handlePrint = async (idformulaire) => {
    try {
      const orderResponse = await axios.get(`https://spira-nature.com/api/order/get/${idformulaire}`);
      const clientResponse = await axios.get('https://spira-nature.com/api/payment/get/');
      
      const client = clientResponse.data.filter((e,i)=>e.idformulaire===idformulaire)[0];
      const orders = orderResponse.data;
     
      const printContent = `
        <div>
          <h2>Détails du client</h2>
          <p>Name: ${client.prenom} ${client.name}</p>
          <p>Email: ${client.email}</p>
          <p>Address: ${client.adresse}</p>
          <p>Phone: ${client.telephone}</p>
          <p>Status: ${client.status}</p>
          <h2>Détails de la commande</h2>
          ${orders.map(order => `
            <div>
              <p>Product Name: ${order.product.name}</p>
              <p>Quantity: ${order.amount}</p>
            </div>
          `).join('')}
        </div>
      `;

      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('</head><body >');
      printWindow.document.write(printContent);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error('Error printing client details:', error);
    }
  };

  return (
    <div className="p-4 bg-[#d2eae5] h-full">
      <h2 className="text-xl font-bold mb-4">Clients</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Nom Complet</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Adresse</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Numéro du Téléphone</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Effacer</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-600 uppercase tracking-wider">Imprimer</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {clients.map((client, index) => (
              <tr key={index}>
                <td onClick={() => handleRowClick(client.idformulaire)} className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{`${client.prenom} ${client.name}`}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.email}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.adresse}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.telephone}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">{client.status}</td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"><MdDeleteOutline onClick={() => del(client.idformulaire)} /></td>
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"><button  className="button" onClick={() => handlePrint(client.idformulaire)}>Print</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {popupVisible && orderDetails && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-bold mb-4">Détails de la commande</h3>
            {orderDetails?.map((order, index) => (
              <div key={index} className="mb-2">
                <p>Nom du produit: {order.product.name}</p>
                <p>Quantité: {order.amount}</p>
              </div>
            ))}
            <button className="button mt-4 px-4 py-2 bg-blue-500 text-white rounded" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Clients;
