import React, { useEffect, useState,useContext, useRef } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ObjContext } from './context';
import Flouci from './checkout/Flouci';
import Checkout from './checkout/Checkout';
import { AmountContext, } from './context1';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [id,setId]=useState(-1)
  const[price,setPrice]=useState(1)
  const navigate=useNavigate()
  const[lo,setLo]=useState(false)
const { obj, setObj,load,setLoad } = useContext(ObjContext);
const {setAmount}=useContext(AmountContext)

  useEffect(() => {
    const token = localStorage.getItem('token');
    

    // Fetch user details
    axios.post('https://spira-nature.com/api/user/user-details', { token })
      .then(response => {
        
        const userId = response.data.idusers;
        setId(response.data.idusers)
        // Fetch cart items for the user
        axios.get(`https://spira-nature.com/api/cart/panier/${userId}`)
          .then(cartResponse => {
            let uniqueItems = [];

    cartResponse.data.forEach(item => {
        let existingItem = uniqueItems.find(uniqueItem => 
            uniqueItem.ProductID === item.ProductID
        );

        if (existingItem) {
            existingItem.quantity += 1;
        } else {
            uniqueItems.push({ ...item, quantity: 1 });
        }
    });
   
            setObj(uniqueItems.map(e=>{
              return {id:e.ProductID,quantity:e.quantity}
            }))
            setCartItems(uniqueItems)
         
          })
          .catch(error => {
            navigate('/register')
          });
      })
      .catch(error => {
        navigate('/register')
      });
  }, [lo,load]);


  const refs = useRef([]);

  // Function to delete item from cart
  const deleteCartItem = ( productId) => {
    axios.delete(`https://spira-nature.com/api/cart/delete/${id}`, { data: { id: productId } })
      .then(response => {
        // Remove the item from the cartItems state
        setCartItems(prevItems => prevItems.filter(item => item.id !== id));
      })
      .catch(error => console.log('Error deleting cart item:', error));
  };
  const handleQuantityChange = (id, quantity) => {
    const parsedQuantity = parseInt(quantity, 10);

    if (isNaN(parsedQuantity)) {
      console.error('Invalid quantity:', quantity);
      return;
    }
   
    setObj(prevObj => {
      // Ensure prevObj is always an array
      if (!Array.isArray(prevObj)) {
        
        return [
          ...prevObj,
          { id, quantity: parsedQuantity }];
      }

      // Find index of the existing item
      const existingItemIndex = prevObj.findIndex(item => item.id === id);

      if (existingItemIndex !== -1) {
        // Update the quantity of the existing object
        const updatedObj = [...prevObj];
        updatedObj[existingItemIndex].quantity = parsedQuantity;
        return updatedObj;
      } else {
        // Add a new object with the given id and quantity
        return [...prevObj, { id, quantity: parsedQuantity }];
      }
    });

   
  };

  
 
  const [total, setTotal] = useState(0);
   setTimeout(() => {
    var s=0
    if (refs){
      refs?.current.map(e=>{
        if (e){
          var r=e?.innerHTML.split(" ")[0]
          s+=parseFloat(r)
        }
       
   
        return s
       })
       setTotal(s)
       
    }
    }
    , 50); 
   
 

  const[reload,setRreload]=useState(false)
  useEffect(()=>{
    setTimeout(() => {
      var s=0
      refs?.current.map(e=>{
        if (e){
          var r=e?.innerHTML.split(" ")[0]
          return s+=parseFloat(r)
        }
     
      })
      setTotal(s)
    }, 500);
   
  },[reload])

  return (
    <div>
      <div className='ml-4 md:ml-40 mt-20'>
        <h1 className='text-[#0072a4]'>
          Home / <span className='text-black'> Panier</span>
        </h1>

        <div className='grid grid-cols-4 md:grid-cols-4 max-sm:hidden mt-10 bg-[#fef9f3] shadow items-center justify-start h-14 w-full md:w-5/6'>
          <h1 className='ml-10 '>Produit</h1>
          <h1 className=' '>Prix</h1>
          <h1 className='-ml-20 '>Quantité</h1>
          <h1 className='-ml-24 '>Total</h1>
        </div>
        {cartItems.map((cartItem, i) => (
          
          <div
            key={i}
            className='grid grid-cols-5   mt-10 bg-[#fef9f3] shadow items-center h-20 md:h-14 w-full md:w-5/6 md:p-0'
          >
            <img className='w-10  md:ml-10' src={JSON.parse(cartItem?.product.images)[0]} alt='' />
            <h1  className=' md:ml-10'>{cartItem?.product?.sale?(cartItem?.product?.price-((cartItem?.product?.price*cartItem?.product?.sale)/100)): cartItem?.product?.price} DT</h1>
            <input
              className='w-16  md:ml-10 border-gray-300 border rounded'
              type='number'
              name={cartItem.ProductID}
              defaultValue={cartItem.quantity}

              onChange={(e) => {handleQuantityChange(cartItem.ProductID,e.target.value)
                cartItem.quantity=e.target.value
                setRreload(!reload)
                
               
              }}
            />
            <h1  ref={el => refs.current[i] = el}  className=' md:ml-20'>{cartItem?.product?.sale?((cartItem?.product?.price-((cartItem?.product?.price*cartItem?.product?.sale)/100))*cartItem.quantity).toFixed(1): (cartItem?.product?.price*cartItem.quantity).toFixed(1)}  DT</h1>
            <MdDeleteOutline
              className='cursor-pointer text-[#0072a4]  md:ml-0'
              onClick={() => {
                setLo(!lo)
                 deleteCartItem(cartItem.ProductID)
                 setLoad(!load)
                 setRreload(!reload)
              }
              }
            />
            
          </div>
        ))}
  <div className='flex justify-evenly'>
        <div className='flex flex-col md:flex-row justify-between mt-10 w-full md:w-5/6'>
          <div className='shadow cursor-pointer flex justify-center items-center bg-[#fef9f3] border-gray-300 border w-full md:w-40 h-14 rounded text-sm mb-4 md:mb-0'>
          <Link to={'/shop'}>Continuer vos achats</Link> 
          </div>
         
        </div>

        <div className='mb-auto flex-row justify-center items-center  float-none md:float-right mt-10 mr-0 bg-[#fef9f3] md:mr-[247px] shadow  rounded w-full md:w-80 h-fit p-4'>
          <h1 className='ml-2 mt-2'>Total du panier</h1>
          <hr className='text-gray-300 w-5/6' />
          {setAmount(total)}
          <h3 className='ml-2 mt-6'>Total: {total} DT</h3>
          <Link   to={{
    pathname: '/formulaire',
    
  }}>
            <div
              className='shadow cursor-pointer flex justify-center items-center border-gray-300 border rounded md:ml-20 bg-[#0072a4] text-white w-full md:w-48 h-12 mt-4'
              // onClick={handleBothCheckouts}
            >
              Passer à la caisse
            </div>
          </Link>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
