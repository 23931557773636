import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div>
    <div className="flex justify-center items-center min-h-screen bg-green-100">
      <div className="text-center bg-white p-6 border border-blue-200 rounded-md shadow-md">
        <h1 className="text-2xl font-bold text-blue-700 mb-4">Erreur</h1>
        <p className="text-blue-700 mb-2">Il semble que quelque chose se soit mal passé avec votre transaction.</p>
        <p className="text-blue-700 mb-4">Veuillez vérifier votre numéro de panier ou les détails de paiement et réessayer.</p>
        <Link to="/">
          <button className="button mt-4"> 
          Retour à la page d’accueil
          </button>
        </Link>
      </div>
    </div>
    </div>
  );
}

export default Error;
