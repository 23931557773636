import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContactAdmin = () => {
  const [contacts, setContacts] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('https://spira-nature.com/api/contact/contacts');
      setContacts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Failed to fetch contacts. Please try again later.');
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="p-5 text-center text-gray-500">Loading contacts...</div>;
  }

  if (error) {
    return <div className="p-5 text-center text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-row p-5 space-x-4 bg-[#d2eae5] h-full">
      <div className="flex-1 m-2 p-4 border border-gray-300 rounded-lg bg-white overflow-y-auto shadow-md">
        {contacts.map(({ idcontact, name, email, message }) => (
          <div 
            key={idcontact} 
            className="flex items-center p-4 mb-4 rounded-[50px] border border-blue-500 cursor-pointer hover:bg-gray-50"
            onClick={() => setSelectedMessage({ name, email, message })}
          >
            <div>
              <p className="font-bold text-blue-500">{name}</p>
              <p className="text-sm text-gray-500">{email}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex-1 m-2 p-6 border border-gray-300 rounded-lg bg-white overflow-y-auto shadow-md">
        {selectedMessage ? (
          <>
            <h2 className="text-2xl font-bold mb-4">Message</h2>
            <div className="flex items-center mb-4">
              <div>
                <p className="font-bold text-blue-500">{selectedMessage.name}</p>
                <a 
                  href={`mailto:${selectedMessage.email}`} 
                  className="text-sm text-gray-500 underline"
                >
                  {selectedMessage.email}
                </a>
              </div>
            </div>
            <p>{selectedMessage.message}</p>
          </>
        ) : (
          <p className="text-gray-600">Select a message to view details</p>
        )}
      </div>
    </div>
  );
};

export default ContactAdmin;