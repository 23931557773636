import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus } from "react-icons/fa6";
import { MdDeleteOutline } from "react-icons/md";
const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [showAddBrandForm, setShowAddBrandForm] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [brandLogo, setBrandLogo] = useState('');
  const [load,setLoad]=useState(false)
  const delbrand=(id)=>{
    axios.delete(`https://spira-nature.com/api/brand/del/${id}`).then(e=>setLoad(!load)).catch(e=>console.log(e))
  }
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get('https://spira-nature.com/api/brand/get');
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, [load]);

  const toggleAddBrandForm = () => {
    setShowAddBrandForm(!showAddBrandForm);
  };

  const handleAddBrand = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://spira-nature.com/api/brand/add', {
        name: brandName,
        image: brandLogo,
      });

      setBrands([...brands, response.data]);
      setBrandName('');
      setBrandDescription('');
      setBrandLogo('');
      setShowAddBrandForm(false);
    } catch (error) {
      console.error("Error adding brand:", error);
    }
  };

  return (
    <div className="p-4 bg-[#d2eae5] h-full">
      <div className='flex flex-row justify-between items-center gap-4'>
        <h1 className='text-3xl'>Brands</h1>
        <div
          className='flex flex-row items-center justify-between gap-4 bg-white p-2 border-2 border-blue-500 text-blue-500 rounded-lg shadow cursor-pointer'
          onClick={toggleAddBrandForm}
        >
          <h1>Add Brand</h1>
          <FaPlus />
        </div>
      </div>
      {showAddBrandForm && (
        <div className='bg-blue-500 p-6 rounded-lg shadow mt-4'>
          <h2 className='text-2xl mb-4 text-white'>Add New Brand</h2>
          <form className='flex flex-col items-center gap-4' onSubmit={handleAddBrand}>
            <input
              type='text'
              placeholder='Brand Name'
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              className='p-2 rounded-lg w-[90%]'
              required
            />
            <textarea
              placeholder='Brand Description'
              value={brandDescription}
              onChange={(e) => setBrandDescription(e.target.value)}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='text'
              placeholder='Logo URL'
              value={brandLogo}
              onChange={(e) => setBrandLogo(e.target.value)}
              className='p-2 rounded-lg w-[90%]'
              required
            />
            <button type='submit' className='button bg-white text-blue-500 w-1/2 border-white rounded'>Add Brand</button>
          </form>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
        {brands.map((brand) => (
          <div key={brand.idbrand} className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
            <img className="w-full h-48 object-cover" src={brand.image} alt={`${brand.name} logo`} />
            <div className="flex flex-row justify-between items-center p-6">
              <h3 className="text-xl font-semibold mb-2">{brand.name}</h3>
              <MdDeleteOutline className='cursor-pointer' onClick={()=>delbrand(brand.idbrand)} size={20}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
