import React from 'react'
import axios from 'axios'
import {useContext} from 'react'
import { ObjContext } from '../context'
import { AmountContext } from '../context1'
import { useNavigate } from 'react-router-dom'
const Flouci = ({obj}) => {
    const navigate=useNavigate()
    const {amount}=useContext(AmountContext)
    const pay=()=>{
      if (obj?.name && obj?.prenom && obj?. telephone && obj?.email && obj?.adresse && obj?.ville && obj?.status ){
        axios.post('https://spira-nature.com/api/init-payment',{amount:`${amount*1000}`})
        .then(r=>window.location=r.data.payUrl).catch(err=>{
            navigate('/cancel')
        })
      }
        
    }
  return (
    <div>
<button onClick={()=>pay()}>Konnect</button>
    </div>
  )
}

export default Flouci