import React from 'react'
import Details from './Details'
import DashboardChart from './DashboardChart'
function Dash() {
  return (
    <div className="bg-[#d2eae5]">
    <div className='flex flex-col gap-4 p-8'>
    <div>
    <h1 className='text-3xl'>Dashboard</h1>
    </div>
    <Details/>
</div>
<div className='flex justify-center h-full'>
    <DashboardChart/>
</div> 
</div>
  )
}

export default Dash