import React, { useState, useEffect } from 'react';

function Blog() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blogDetails, setBlogDetails] = useState({});
  const [blogIndex, setBlogIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBlogIndex(prevIndex => (prevIndex + 1) % blogs.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const openModal = (details) => {
    setBlogDetails(details);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const blogs = [
    {
      title: "Blog Title 1",
      author: "Author Name 1",
      date: "Date 1",
      description: "This is a detailed description of the first blog post.",
      images: [
        "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/skincare-product-banner-ads-design-template-4fe7856b75b041c93dc35633d5786902_screen.jpg?ts=1646835328",
        "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/women-expert-face-wash-design-template-96190396e04ff23f5428a101c251a567_screen.jpg?ts=1680440426",
        "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/skin-care-design-template-fd7b7442976ac6a7f37974eb83ef8890_screen.jpg?ts=1637035332"
      ]
    },
    {
      title: "Blog Title 2",  
      author: "Author Name 2",
      date: "Date 2",
      description: "This is a detailed description of the second blog post.",
      images: [
        "https://www.screenhaircare.com/contrib/uploads/header-mobile-puredips.jpg",
        "https://www.screenhaircare.com/contrib/uploads/mobile_base_slider_sito_purest_001.jpg",
        "https://m.media-amazon.com/images/I/71RHLqRs9oL._AC_UF1000,1000_QL80_DpWeblab_.jpg"
      ]
    },
    {
      title: "Blog Title 3",
      author: "Author Name 3",
      date: "Date 3",
      description: "This is a detailed description of the third blog post.",
      images: [
        "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/beauty-salon-and-skin-care-design-template-3c131da58827bbf928b34bdb854d5cd3_screen.jpg?ts=1657270114",
        "https://www.screenhaircare.com/contrib/uploads/header-mobile-puredips.jpg",
        "https://marketplace.canva.com/EAFNfDYMoHY/1/0/1600w/canva-colorful-skin-care-instagram-story-qSnJBTIptH0.jpg"
      ]
    }
  ];

  return (
    <div className="flex flex-wrap justify-center gap-32 mt-[30px] mb-[30px]">
      {blogs.map((blog, index) => (
        <div key={index} className="border border-gray-300 rounded-lg shadow-md p-4 w-[300px]">
          <img 
            src={blog.images[blogIndex]} 
            alt="Blog post" 
            className="w-full h-[300px] object-cover rounded-lg mb-4"
          />
          <h2 className="text-lg font-bold mb-2">{blog.title}</h2>
          <div className="text-sm text-gray-600 mb-2">by {blog.author} on {blog.date}</div>
          <button onClick={() => openModal({ ...blog, imgSrc: blog.images[blogIndex] })} className="button left-10">Read More</button>
        </div>
      ))}

      {isModalOpen && (
        <Modal details={blogDetails} onClose={closeModal} />
      )}
    </div>
  );
}

function Modal({ details, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
        <img 
          src={details.imgSrc} 
          alt="Blog post" 
          className="w-full h-auto rounded-t-lg mb-4"
        />
        <h2 className="text-lg font-bold mb-2">{details.title}</h2>
        <div className="text-sm text-gray-600 mb-2">by {details.author} on {details.date}</div>
        <p className="text-sm leading-relaxed mb-4">{details.description}</p>
        <button onClick={onClose} className="button">Close</button>
      </div>
    </div>
  );
}

export default Blog;
