import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Blogs() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [blog, setBlog] = useState(null);
    const navigate=useNavigate()
    const fetchFirstBlog = async () => {
        try {
            const response = await axios.get('https://spira-nature.com/api/blog/get');
            const blogs = response.data;
            if (blogs.length > 0) {
                setBlog(blogs[0]);
            }
        } catch (error) {
            alert(error)
            navigate('/')
            
        }
    };

    useEffect(() => {
        fetchFirstBlog();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(currentIndex => (currentIndex + 1) % (blog?.images?.length || 1));
        }, 5000);

        return () => clearInterval(interval);
    }, [blog]);

    if (!blog) {
        return <div>Chargement...</div>;
    }

    return (
        <div className="p-4 md:p-8 overflow-hidden">
            <div className='flex flex-col md:flex-row justify-center items-center gap-4 md:gap-[120vh]'>
                <div className='flex flex-row items-center gap-2'>
                    <h2 className="text-7xl text-[#0072a4] md:ml-16 font-brownhill font-bold">Blog</h2>
                </div>
                <Link to='/allblogs'>
                    <h2 className="button ">Voir les blogs</h2>
                </Link>
            </div>
            <div className="relative flex flex-col md:flex-row p-4 md:p-12 md:left-[15vh]">
                <div className="w-full h-full md:w-1/2 relative">
                    {blog.images && blog.images.length > 0 && (
                        <img
                            src={JSON.parse(blog.images)[0]}
                            alt="Blog"
                            className="w-full h-[50%] border-transparent rounded"
                        />
                    )}
                    <div className="relative md:absolute md:bottom-[20%] md:left-[50%] bg-[#fef9f3] p-4 w-full md:w-[80vh] h-auto md:h-[40vh] border-transparent rounded shadow mt-4 md:mt-0">
                        <h2 className="text-xl md:text-2xl font-bold text-[#0072a4] text-center mb-2">{blog.title}</h2>
                        <p className="text-gray-500 text-center">Par administrateur</p>
                        <p className="mb-2 text-gray-500 line-clamp-6">{blog.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;