import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Ensure axios is installed: npm install axios

function BlogCard({ image, title, id }) {
  return (
    <div className="flex flex-col items-center mb-12">
      <Link to={`/oneblog/${id}`}>
        <img src={image} alt={title} className="w-full h-40 sm:h-60 md:h-[40vh] object-cover border rounded-lg" />
      </Link>
      <div className="mt-4 text-center">
        <h3 className="text-lg sm:text-xl font-bold">{title}</h3>
      </div>
    </div>
  );
}

function AllBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://spira-nature.com/api/blog/get'); // Adjust the URL to your actual endpoint
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
     <div className="w-full h-[80vh]">
        <video 
          src="/spiravid.mp4" 
          alt="Background Video" 
          className="w-full h-[60vh] object-fill md:object-fill md:h-full" 
          autoPlay 
          loop 
          muted 
        />
      </div>

      <div className="p-4 sm:p-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8 lg:gap-12">
          {blogs.map((blog) => (
            <BlogCard
              key={blog.idblog}
              image={JSON.parse(blog.images)[0]} // Assuming images is an array and displaying the first image
              title={blog.title}
              id={blog.idblog}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AllBlogs;
