import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css'; 

function ContactMap() {
    const markerPO = new Icon ({
        iconUrl: 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png',
        iconSize: [35, 35],
    });

    const mapStyle = {
        width: '50%',
        height: '75%',
        borderRadius: '15px',
    };

    return (
        <div className="map-container" style={mapStyle}>
            <MapContainer center={[36.873218246518164, 10.181320955312367]} zoom={15} style={mapStyle}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[36.873218246518164, 10.181320955312367]} icon={markerPO}>
                    <Popup autoOpen={true}>
                        Spira Nature
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
}

export default ContactMap;
