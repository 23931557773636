import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ProductDisplay = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Tous');
  const [sortOrder, setSortOrder] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Fetch products from the backend
    axios.get('https://spira-nature.com/api/product/getAllProd')
      .then(response => {
        setProducts(response.data);
        setFilteredProducts(response.data);

        // Extract categories and remove duplicates
        const uniqueCategories = ['Tous', ...new Set(response.data.map(product => product.category))];
        setCategories(uniqueCategories);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    let updatedProducts = products;
    if (location.search.split('=')[1]){
      updatedProducts = updatedProducts.filter(product => product.name === `${decodeURIComponent(location.search.split('=')[1])}` || product.marque === `${decodeURIComponent(location.search.split('=')[1])}`);

    }
    // Filter by category or type
    if (selectedCategory !== 'Tous') {
      if (selectedCategory === 'Packs') { 
        updatedProducts = updatedProducts.filter(product => product.type === 'packet');
      } else {
        updatedProducts = updatedProducts.filter(product => product.category === selectedCategory);
      }
    }

    // Sort by price
    if (sortOrder === 'asc') {
      updatedProducts = updatedProducts.sort((a, b) => a.price - b.price);
    } else if (sortOrder === 'desc') {
      updatedProducts = updatedProducts.sort((a, b) => b.price - a.price);
    }
    
    setFilteredProducts(updatedProducts);
  }, [selectedCategory, sortOrder, products]);

  return (
    <div className="flex flex-col p-5 space-y-6">
      {/* Filter Navbar */}
      <div className="bg-[#fef9f3] w-full p-4 border border-gray-300 rounded-lg shadow-md">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">Filtres</h2>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <select
              className="py-2 px-4 rounded-lg border border-[#0072a4] bg-white"
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="">Sort by price</option>
              <option value="asc">Price: Low to High</option>
              <option value="desc">Price: High to Low</option>
            </select>
            {/* Dropdown for categories */}
            <select
              className="py-2 px-4 rounded-lg border border-[#0072a4] bg-white"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categories.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
              <option value="Packs">Packs</option>
            </select>
          </div>
        </div>
      </div>

      {/* Product Display Section */}
      <div className="bg-[#fef9f3] w-full p-6 border border-gray-300 rounded-lg shadow-md">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProducts.map(product => (
            <div key={product.id} className="w-full p-4 bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300">
              <Link to={`/details/${product.idpoducts}`}>
                <img src={JSON.parse(product.images)[0]} alt={product.name} className="w-full h-56 object-cover rounded-t-lg mb-4" />
              </Link>
              <h3 className="text-lg font-bold text-gray-800">{product.name}</h3>
              <p className='text-sm text-gray-600 truncate'>{product.description}</p>
              {product?.sale?
                    <div className='flex gap-[10px]'>
                    <h1 className='text-[#0072a4]'>{product?.price-((product?.price*product?.sale)/100)} DT</h1>
                    <h1 className=' line-through'>{product?.price} DT</h1>
                    </div>:
                    <h1 className='text-[#0072a4]'>{product?.price} DT</h1>

                } 
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDisplay;
