import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
function WhishList() {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [id,setId]=useState(1)
  const navigate=useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    // Fetch user details
    axios.post('https://spira-nature.com/api/user/user-details', { token })
      .then(response => {
        const userId = response.data.idusers;
        setId(userId);
        axios.get(`https://spira-nature.com/api/wish/panier/${userId}`)
          .then(wishlistResponse => {
            setWishlistItems(wishlistResponse.data);
          })
          .catch(error => {
            navigate('/register')
          });
      })
      .catch(error => {
        navigate('/register')
      });
  }, []);

  const handleDelete = (productId) => {
    console.log(productId)
    axios.delete(`https://spira-nature.com/api/wish/delete/${id}`,{data:{id: productId}})
      .then(() => {
        setWishlistItems(prevItems => prevItems.filter(item => item.productID !== productId));
      })
      .catch(error => console.log('Error deleting wishlist item:', error));
  };


  return (
    <div className=' min-h-[400px]'>
      <div className="max-w-7xl mx-auto p-4">
        <div className="flex justify-between items-center mt-4">
          <span className="text-xl font-bold text-[#0072a4]">Liste de souhaits ({wishlistItems.length})</span>
        </div>
        <div className="flex flex-wrap gap-4 mt-4">
          {wishlistItems.map(item => (
            <div key={item.id} className="w-1/4 bg-white p-4 shadow-md">
              <div className="relative">
              <Link to={`/details/${item?.productID}`} className="cursor-pointer">
                <img src={JSON.parse(item.product.images)[0]}  className="w-full h-48 object-cover rounded-t-lg" />
              </Link>               
              </div>
              <div className="text-sm flex justify-between mt-[15%]">
                <div>
                <div className="font-bold mb-1 text-[15px]">{item?.product?.name}</div>
                <div className="text-[#0072a4] font-bold">{item?.product?.price} DT</div>
                </div>
                <MdDelete 
                  onClick={() => handleDelete(item?.productID)} 
                  className=" cursor-pointer text-[#0072a4]  " size={30}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhishList;