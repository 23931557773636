import React from 'react';


function About() {
  return (
    <div>
     <div className="w-full h-[80vh]">
        <video 
          src="/spiravid.mp4" 
          alt="Background Video" 
          className="w-full h-[60vh] object-fill md:object-fill md:h-full" 
          autoPlay 
          loop 
          muted 
        />
      </div>

      <div className='mb-8'></div> 
{/* 
      <div className='flex justify-center mb-8'>
        <div className='text-center max-w-lg'>
          <p className="text-lg lg:text-xl">Avez-vous déjà réalisé que votre reflet est uniquement le vôtre ? Chaque détail parle de votre individualité. Nous avons constaté une lacune dans la reconnaissance de ce caractère unique par l'industrie de la beauté et sommes intervenus. Function of Beauty vous célèbre avec des formules sur mesure d'origine naturelle, élaborées par des chimistes et des experts en beauté internes. Depuis 2015, nous avons créé 15 millions de produits uniques et personnalisés, et ce n'est pas fini...</p>
        </div>
      </div> */}

      <div className='mb-8'></div> 

      <div className='flex flex-col items-center w-full'>
        <div className='flex flex-wrap w-[80%] bg-[#fef9f3] my-4 p-4 rounded-lg'>
          <div className='w-full lg:w-1/2 flex '>
            <img src="" alt="" className='w-2/3 md:w-full lg:w-4/5' />
          </div>
              <p className="text-lg lg:text-xl ">Depuis 2018, notre parapharmacie est spécialisée dans les produits naturels, et plus particulièrement dans la spiruline. Nous avons constaté que chaque personne est unique et mérite des soins adaptés à ses besoins spécifiques. Chez Spira Nature, nous célébrons cette individualité avec des formules sur mesure, élaborées par nos experts . Grâce à notre engagement, nous avons aidé des milliers de clients à améliorer leur bien-être avec des produits naturels de haute qualité.</p>
            
         
        </div>

        <div className='mb-8'></div> 

        <div className='flex flex-wrap w-[80%] bg-[#fef9f3] my-4 p-4 rounded-lg'>
          <div className='w-full lg:w-1/2 flex justify-center lg:justify-start items-center'>
            <div className='max-w-lg'>
              <p className="text-lg lg:text-xl">Le TONILINE est constitué d’algue Spirulina platensis, à 100% naturelle et écologique sans aucun additif et sans aucun conservateur.
Il est produit en bassins complètement contrôlés et enrichis en eau de mer ce qui lui assure une richesse en minéraux marins dont principalement le magnésium. <br /> Cet élément minéral a une importance primordiale dans les fonctions cérébrales et musculaires. Or, avec la dépense énergétique due à l’effort sportif, l’organisme peut en perdre jusqu’à 30 à 40 mg par heure avec la sueur.
Toutes les spirulines ne sont pas équivalentes quant à leur magnésium, les meilleures étant celles qui sont, comme le TONILINE, enrichies à l’eau de mer.</p>
            </div>
          </div>
          <div className='w-full lg:w-1/2 flex justify-center lg:justify-end'>
            <img src="37053823-6fbd-4128-8445-4af648d202d2.jpeg" 
            alt="" className='w-full md:w-full md:h-[80vh] lg:w-4/5 md:object-fit' />
          </div>
        </div>

        <div className='mb-8'></div> 

        <div className='flex flex-wrap w-[80%] bg-[#fef9f3] my-4 p-4 rounded-lg'>
          <div className='w-full lg:w-1/2 flex '>
            <img src="87f3f3a6-2248-43ac-8670-14cb361ed9f4.jpeg" 
            alt="" className='w-full md:w-full md:h-[80vh] lg:w-4/5 md:object-fit' />
          </div>
          <div className='w-full lg:w-1/2 flex justify-center lg:justify-start items-center'>
            <div className='text-left max-w-lg'>
              <h1 className="text-lg lg:text-xl">Le Phycospir est un complément 100% naturel extrait de la spiruline, riche en protéines, vitamines, minéraux et antioxydants. Ce produit renforce le système immunitaire, améliore l'énergie et favorise une meilleure récupération. Grâce à ses nombreux bienfaits, le Phycospir contribue à votre bien-être général et à une santé optimale.</h1>
            </div>
          </div>
        </div>
      </div>

      <div className='mb-8'></div> 

      <div className='flex flex-col items-center mb-6'>
    <h1 className='text-2xl lg:text-3xl font-bold mb-4'>Nos fondateurs</h1>
    <div className='flex flex-wrap justify-center w-full px-2'>
        <div className='border border-gray-300 rounded-lg shadow-md p-4 mx-4 mb-3 w-4/5 md:w-2/5 lg:w-1/3 xl:w-1/4 bg-[#fef9f3]'>
            <img src="85ec3c4f-297a-4484-99da-a4d118bcebb7.jpeg" 
            alt="" className='w-full h-auto md:h-[60vh] rounded-lg mb-3' />
            <h2 className="text-md font-semibold text-center mb-1">Eya Haj Salem</h2>
            <p className="text-center text-sm">ingénieure en génie biologique et fondatrice de Spira Nature, vous propose de la spiruline naturelle de haute qualité. Notre mission est d'améliorer votre bien-être et de prolonger votre vie grâce à des produits respectueux de nos principes.</p>
        </div>
        <div className='border bg-[#fef9f3] border-gray-300 rounded-lg shadow-md p-4 mx-2 mb-3 w-4/5 md:w-2/5 lg:w-1/3 xl:w-1/4 '>
            <img  src="dc152ad9-36ae-4210-a5e2-4c5d524bc330.jpeg" 
            alt="" className='w-full h-auto md:h-[60vh] rounded-lg mb-3' />
            <h2 className="text-md font-semibold text-center mb-1">Mabrouk Haj Salem</h2>
            <p className="text-center text-sm">médecin avec 30 ans d'expérience, a fondé Spira Nature pour promouvoir la prévention et aider ses patients grâce à la spiruline. Nous offrons des produits naturels de haute qualité pour améliorer votre bien-être et prolonger votre vie</p>
        </div>
    </div>
</div>

    </div>
  );
}

export default About;
