import {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
function Login() {
  const navigate=useNavigate()
  const [data, setData] = useState({
    email: "",
    password: ""
});

useEffect(()=>{
    if(localStorage.getItem('token')){
        navigate('/')
    }
},[])
const handleOnChange=(e)=>{
  const {name,value}=e.target
  setData((prev)=>{
    return{
      ...prev,
      [name]:value
    }
  })
}
const login=()=>{
  axios.post('https://spira-nature.com/api/user/login',data)
  .then(r=>{
      if (r.status===200){
          localStorage.setItem('token',r.data.token)
          localStorage.setItem('rea',Date.now())
          navigate('/')
          
      }
      
      })
  .catch(err=>alert(err.response.data))
}
  return (
    <div className="space-y-4">
      <input 
        type="email" 
        name='email'
        onChange={(e)=>handleOnChange(e)}
        placeholder="Email" 
        className="w-full p-2 border border-gray-300 rounded" 
      />
      <input 
        type="password" 
        name='password'
        onChange={(e)=>handleOnChange(e)}
        placeholder="mot de passe" 
        className="w-full p-2 border border-gray-300 rounded" 
      />
      <div className="w-full flex justify-center items-center cursor-pointer bg-black text-white p-2 rounded"
      onClick={()=>login()}
      >Se connecter</div>
    </div>
  );
}

export default Login;
