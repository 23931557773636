import React, { createContext, useState, useContext } from 'react';
const AmountContext = createContext();
export const AmountProvider = ({ children }) => {

    const [amount, setAmount] = useState(0);
    const [formid,setfid]=useState(0)
    return (
        <AmountContext.Provider value={{ amount, setAmount,formid,setfid }}>
          {children}
        </AmountContext.Provider>
      );
    };
export { AmountContext };

