import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductsOnSale from './ProductOnSale';
import Brands from '../Brands';
import Blogs from './Blogs';
import Delivery from './Delivery';
import Pack from '../Packs';
const HomePage = () => {
  const navigate=useNavigate()
  useEffect(()=>{
    const time=localStorage.getItem('rea')
    if (Date.now()-Date.now(time)>=24){
      navigate('/register')
      localStorage.clear()
    }
  },[])
 
  return (
    <div className="relative flex flex-col min-h-screen gap-12">
      {/* Background Video */}
      <div className="w-full h-[80vh]">
        <video 
          src="/spiravid.mp4" 
          alt="Background Video" 
          className="w-full h-[60vh] object-fill md:object-fill md:h-full" 
          autoPlay 
          loop 
          muted 
        />
      </div>

      {/* Content */}
      <div className="flex-grow mt-[150px]">
        <ProductsOnSale />
      </div>
      <hr className="mx-auto my-4 w-3/4 border-[#fef9f3] rounded"/>
      <div>
      <Brands/>
      </div>
      <hr className="mx-auto my-4 w-3/4 border-[#fef9f3] rounded"/>
      <div>
        <Pack/>
      </div>
      <div>

      <Blogs/>
      </div>
      <div>
      <Delivery/>
      </div>
     
    </div>
  );
};

export default HomePage;
