import { createSlice } from "@reduxjs/toolkit" 

const initialState = {
    idusers:"",
    name: "",
    email: "",
    token: ""
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        logout: (state) => {
            state.name = ""
            state.email = ""
            state.token = ""
        },
        setUser: (state, action) => {
            state.idusers=action.payload.idusers
            state.name = action.payload.name 
            state.email = action.payload.email 
        }
    }
})

export const { setToken, setUser, logout } = userSlice.actions
export default userSlice.reducer
