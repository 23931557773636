import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';
import dayjs from 'dayjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DashboardChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Sales',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    axios.get('https://spira-nature.com/api/payment/get')
      .then(response => {
        const data = response.data;
        console.log('Fetched data:', data);  // Log fetched data

        // Group data by month
        const groupedData = data.reduce((acc, item) => {
          console.log('Processing item:', item);  // Log each item
          const month = dayjs(item.createdAt).format('YYYY-MM');
          if (!acc[month]) acc[month] = data.filter((e,i)=>e.createdAt.substring(0, 7)===month).length 
          const value = parseFloat(item.value);  // Ensure the value is a number
          if (!isNaN(value)) {
            acc[month] += value;
          } else {
            console.error(`Invalid value for item: ${item}`);
          }
          return acc;
        }, {});

        const labels = Object.keys(groupedData);
        const values = Object.values(groupedData);

        console.log('Grouped Data:', groupedData);  // Log grouped data
        console.log('Labels:', labels);  // Log labels
        console.log('Values:', values);  // Log values

        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Clients',
              data: values,
              backgroundColor: 'rgba(59, 130, 246, 0.2)',
              borderColor: 'rgba(59, 130, 246, 0.2)',
              borderWidth: 1,
            },
          ],
        });
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Client par mois',
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 20,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 20,
          },
        },
      },
    },
  };

  return (
    <div className="flex justify-center p-4 bg-white shadow-lg rounded-lg w-[80%] h-[50vh]">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default DashboardChart;
