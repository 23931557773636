import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const ObjContext = createContext();

// Create a provider component
export const ObjProvider = ({ children }) => {
const[load,setLoad]=useState(false)
const [obj, setObj] = useState([]);
useEffect(()=>{
  localStorage.setItem('prodobj', JSON.stringify(obj))
},[obj])
return (
    <ObjContext.Provider value={{ obj, setObj,load,setLoad }}>
      {children}
    </ObjContext.Provider>
  );
};

// Custom hook to consume the context
export { ObjContext };

export const useObj = () => useContext(ObjContext);
