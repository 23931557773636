import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaUserFriends } from "react-icons/fa";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { LuLineChart } from "react-icons/lu";

function Details() {
  const [orderCount, setOrderCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  useEffect(() => {
    const fetchOrderCount = async () => {
      try {
        const response = await axios.get('https://spira-nature.com/api/order/count');
        setOrderCount(response.data.count);
      } catch (error) {
        console.error("Error fetching order count:", error);
      }
    };

    const fetchClientCount = async () => {
      try {
        const response = await axios.get('https://spira-nature.com/api/payment/count');
        setClientCount(response.data.count);
      } catch (error) {
        console.error("Error fetching client count:", error);
      }
    };

    const fetchTotalSales = async () => {
      try {
        const response = await axios.get('https://spira-nature.com/api/order/get');
        const orders = response.data;
        const total = orders.reduce((acc, order) => acc + order.amount, 0);
        setTotalSales(total);
      } catch (error) {
        console.error("Error fetching total sales:", error);
      }
    };

    fetchOrderCount();
    fetchClientCount();
    fetchTotalSales();
  }, []);

  return (
    <div className='flex flex-row justify-center gap-24'>
      <div className='flex flex-row justify-center items-center bg-white border rounded-lg w-[250px] h-[150px] gap-16 p-2'>
        <div className='flex flex-col'>
          <h1>Total Clients</h1>
          <span className='text-xl'>{clientCount}</span>
        </div>
        <div>
          <FaUserFriends className='text-[60px] text-violet-500 border rounded-2xl bg-violet-200 p-2'/>
        </div>
      </div>
      <div className='flex flex-row justify-center items-center bg-white border rounded-lg w-[250px] h-[150px] gap-16 p-2'>
        <div className='flex flex-col'>
          <h1>Orders</h1>
          <span className='text-xl'>{orderCount}</span>
        </div>
        <div>
          <GiCardboardBoxClosed className='text-[60px] text-amber-500 border rounded-2xl bg-amber-200 p-2'/>
        </div>
      </div>
      <div className='flex flex-row justify-center items-center bg-white border rounded-lg w-[250px] h-[150px] gap-16 p-2'>
        <div className='flex flex-col'>
          <h1>Total Sales</h1>
          <span className='text-xl'>{totalSales.toFixed(2)} DT</span>
        </div>
        <div>
          <LuLineChart className='text-[60px] text-teal-500 border rounded-2xl bg-teal-200 p-2'/>
        </div>
      </div>
    </div>
  );
}

export default Details;