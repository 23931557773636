import React, { useState,useContext } from 'react';
import Checkout from './Checkout';
import axios from 'axios';
import { useObj } from '../context';
import Flouci from './Flouci';
import { useNavigate } from 'react-router-dom';
import { AmountContext } from '../context1'
import { ObjContext } from '../context'
const Formulaire = () => {
    const {setfid} = useContext(AmountContext)
    const { obj } = useContext(ObjContext)
//   const obj=useObj()
    const [data, setData] = useState({
        name: "",
        prenom: "",
        telephone: "",
        email: "",
        adresse: "",
        note: "",
        ville: '',
        status:""
    });

    const [errors, setErrors] = useState({});

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(data).forEach((key) => {
            if (!data[key]) {
                newErrors[key] = 'This field is required';
            }
        });
        return newErrors;
    };
    const navigate=useNavigate()
    const submit = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            axios.post('https://spira-nature.com/api/payment/form', data)
                .then(r => {
                   setfid(r.data.idformulaire)
                  localStorage.setItem("sessionid",r.data.idformulaire)
                  
                    // Clear the form inputs after successful submission
                    setData({
                        name: "",
                        prenom: "",
                        telephone: "",
                        email: "",
                        adresse: "",
                        note: "",
                        ville: '',
                        status:""
                    });
                })
                .catch(err => alert('Essayer une autre fois'));
        }
    };
    const submit1 = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            axios.post('https://spira-nature.com/api/payment/form', data)
                .then(r => {
                    setfid(r.data.idformulaire)
                    localStorage.setItem("sessionid",r.data.idformulaire)
                    localStorage.setItem("prodobj",JSON.stringify(obj))
                    // Clear the form inputs after successful submission
                    setData({
                        name: "",
                        prenom: "",
                        telephone: "",
                        email: "",
                        adresse: "",
                        note: "",
                        ville: '',
                    });
                    setTimeout(() => {
                        navigate('/success')
                    }, 50);
                    
                })
                .catch(err => alert('Essayer une autre fois'));
        }
    };
console.log("objj",obj)
    return (
        <div>
            <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Formulaire</h2>
                <div className="space-y-4" style={{ maxHeight: '480px', overflowY: 'auto' }}>
                    <input
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={handleOnChange}
                        placeholder="Nom"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                    <input
                        type="text"
                        name="prenom"
                        value={data.prenom}
                        onChange={handleOnChange}
                        placeholder="Prénom"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.prenom && <p className="text-red-500 text-xs mt-1">{errors.prenom}</p>}
                    <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={handleOnChange}
                        placeholder="Email"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                    <input
                        type="text"
                        name="telephone"
                        value={data.telephone}
                        onChange={handleOnChange}
                        placeholder="Téléphone"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.telephone && <p className="text-red-500 text-xs mt-1">{errors.telephone}</p>}
                    <input
                        type="text"
                        required
                        name="ville"
                        value={data.ville}
                        onChange={handleOnChange}
                        placeholder="Ville"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.ville && <p className="text-red-500 text-xs mt-1">{errors.ville}</p>}
                    <input
                        type="text"
                        name="adresse"
                        value={data.adresse}
                        onChange={handleOnChange}
                        placeholder="Adresse"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.adresse && <p className="text-red-500 text-xs mt-1">{errors.adresse}</p>}
                    <input
                        type="text"
                        name="note"
                        value={data.note}
                        onChange={handleOnChange}
                        placeholder="Note"
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                    {errors.note && <p className="text-red-500 text-xs mt-1">{errors.note}</p>}
                    
                </div>
                <div className='flex justify-evenly'>

                   <div className="flex justify-evenly">
                    
                    <button
                     onMouseEnter={() => setData(prev => ({ ...prev, status: "flouci" }))}
                    onClick={submit}
                        className="button mt-4 ">
                        <Flouci obj={data}/>
                    </button>
                </div>
                    <button
                     onMouseEnter={() => setData(prev => ({ ...prev, status: "livraision" }))}
                    onClick={submit1}
                        className="button mt-4 ml-10">
                        Payment A La Livraison
                    </button> 
                </div>
                
            </div>
        </div>
    );
};

export default Formulaire;
