import { useContext } from 'react';
import { ObjContext } from '../context';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const { obj } = useContext(ObjContext);
  const navigate = useNavigate();

  const pay = () => {

    

    // Proceed with the payment request
    fetch('https://spira-nature.com/api/payment/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: obj,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        console.error('Payment error:', e);
        navigate('/cancel');
      });
  };

  return (
    <div>
    </div>
  );
};

export default Checkout;
