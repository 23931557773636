import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Review = ({ productId,user }) => {
 
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ userID: `${user.idusers}`, productID: productId, message: '' });
  const [l,setL]=useState(false)
const navigate=useNavigate()
 

  const fetchReviews = async () => {
    try {
      const response = await axios.get(`https://spira-nature.com/api/review/get/${productId}`);
      setReviews(response.data);
    } catch (error) {
      alert('')
      navigate('/details/'+productId)
    }
  };
  useEffect(() => {
    fetchReviews();
  }, [l]);

  const handleChange = (e) => {
    setNewReview({ ...newReview, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log(typeof(productId),typeof(user.idusers),user)
    e.preventDefault();
    try {
      const response = await axios.post(`https://spira-nature.com/api/review/add`, newReview);
      // setReviews([...reviews, response.data]);
      setNewReview({ userID:`${user.idusers}`, productID: productId, message: '' });
      setL(!l)
    } catch (error) {
      alert('Essayer une autre fois')
      navigate('/details/'+productId)
    }
  };

  return (
    <div className="bg-[#fef9f3] p-4 md:p-8 shadow w-full md:w-auto rounded-lg">
      <div className='flex flex-col'>
        <div className='flex flex-row gap-2'>
          <span className='text-xl font-bold'>{reviews.length}</span>
          <h2 className="text-lg md:text-xl font-bold mb-2">Commentaires</h2>
        </div>
        <hr className='border-2 border-[#0072a4] w-[14vh]' />
      </div>
      {reviews.length > 0 ? (
        <ul>
          {reviews.map((review, index) => (
            <li key={index} className="mb-4 p-4 rounded-lg flex items-start">
              <img src="https://akademifutbol.com.tr/wp-content/themes/birfanatik/img/icons/newavatar.png" alt="User" className="w-12 h-12 md:w-16 md:h-16 rounded-full mr-4" />
              <div className='flex flex-col gap-2'>
                <div className='flex flex-col'>
                  <p className="text-[#0072a4] font-semibold">{user.name}</p>
                  <hr className='border-2 border-[#0072a4] w-[5vh]' />
                </div>
                <p className="text-gray-700">{review.message}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm text-gray-600">Aucun avis pour l'instant.</p>
      )}
      <form  className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Ajouter un commentaire</h3>
        <textarea
          name="message"
          value={newReview.message}
          required
          onChange={handleChange}
          placeholder="Votre avis"
          className="w-full bg-[#fef9f3] border border-[#0072a4] rounded-lg p-2 shadow"
        ></textarea>
        <div onClick={handleSubmit} type="submit" className="bg-[#0072a4] flex justify-center cursor-pointer min-w-fit  text-white px-4 py-2 mt-2 rounded-lg">Soumettre</div>
      </form>
    </div>
  );
};

export default Review;