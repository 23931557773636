import React from 'react'
import DashNav from './DashNav'
function Dashboard() {
  return (
    <div>
        <div>
            <DashNav/>
        </div>
    </div>
  )
}

export default Dashboard