import React, { useState, useEffect } from 'react';
import { MdDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import axios from 'axios';

function Product() {
  const [products, setProducts] = useState([]);
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [showUpdateProductForm, setShowUpdateProductForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: '',
    quantity: '',
    type: '',
    category: '',
    sale: '',
    marque: '',
    images: []
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editProductId, setEditProductId] = useState(null);
  const [refreshProducts, setRefreshProducts] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, [refreshProducts]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://spira-nature.com/api/product/getAllProd');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const imageUrls = await uploadImages(selectedFiles);
      const productData = {
        ...formData,
        images: imageUrls
      };

      const response = await axios.post('https://spira-nature.com/api/product/add', productData);
      if (response.status === 201) {
        setFormData({
          name: '',
          price: '',
          description: '',
          quantity: '',
          type: '',
          category: '',
          sale: '',
          marque: '',
          images: []
        });
        setSelectedFiles([]);
        setShowAddProductForm(false);
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to add product');
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      let imageUrls = formData.images || [];
      if (selectedFiles.length > 0) {
        const newImageUrls = await uploadImages(selectedFiles);
        imageUrls = [...imageUrls, ...newImageUrls];
      }

      const productData = {
        ...formData,
        images: imageUrls
      };

      const response = await axios.put(`https://spira-nature.com/api/product/put/${editProductId}`, productData);
      if (response.status === 200) {
        setFormData({
          name: '',
          price: '',
          description: '',
          quantity: '',
          type: '',
          category: '',
          sale: '',
          marque: '',
          images: []
        });
        setSelectedFiles([]);
        setShowUpdateProductForm(false);
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to edit product');
      }
    } catch (error) {
      console.error('Error editing product:', error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const response = await axios.delete(`https://spira-nature.com/api/product/del/${id}`);
      if (response.status === 204) {
        setRefreshProducts(!refreshProducts);
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEditProduct = (id) => {
    setEditProductId(id);
    const productToEdit = products.find(product => product.idpoducts === id);
    setFormData({
      ...productToEdit,
      images: JSON.parse(productToEdit.images) // Parse the image URLs to an array
    });
    setShowUpdateProductForm(true);
  };

  const uploadImages = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('img', files[i]);
    }

    try {
      const response = await axios.post('https://spira-nature.com/api/cloudinary', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data.map(file => file.secure_url);
    } catch (error) {
      console.error('Error uploading images:', error);
      return [];
    }
  };

  return (
    <div className='flex flex-col gap-2 p-8 bg-[#d2eae5] w-full h-full'>
      <div className='flex flex-row justify-between items-center gap-4'>
        <h1 className='text-3xl'>Produits</h1>
        <div
          className='flex flex-row items-center justify-between gap-4 bg-white p-2 border-2 border-[#0072a4] text-[#0072a4] rounded-lg shadow cursor-pointer'
          onClick={() => setShowAddProductForm(!showAddProductForm)}
        >
          <h1>Ajouter un produit</h1>
          <FaPlus />
        </div>
      </div>
      {showAddProductForm && (
        <div className='bg-[#0072a4] p-6 rounded-lg shadow'>
          <h2 className='text-2xl text-white mb-4'>Ajouter un nouveau produit</h2>
          <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleAddProduct}>
            <input
              type='text'
              name='name'
              placeholder='Nom du produit'
              value={formData.name}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='price'
              step='0.01'
              placeholder='Prix'
              value={formData.price}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <textarea
              name='description'
              placeholder='Description'
              value={formData.description}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='quantity'
              step='0.01'
              placeholder='Quantité'
              value={formData.quantity}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <select
              name='type'
              value={formData.type}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%] text-gray-400'
            >
              <option value='' disabled selected>Sélectionnez le type</option>
              <option value='single'>unique</option>
              <option value='packet'>Pack</option>
            </select>
            <input
              type='text'
              name='category'
              placeholder='Categorie'
              value={formData.category}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='sale'
              placeholder='Promotion'
              value={formData.sale}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='text'
              name='marque'
              placeholder='Marque'
              value={formData.marque}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='file'
              name='img'
              onChange={handleFileChange}
              className='p-2 rounded-lg w-[90%]'
              multiple
            />
            <button className='button bg-white text-[#0072a4] w-1/2 border-white rounded'>Ajouter</button>
          </form>
        </div>
      )}

      {showUpdateProductForm && (
        <div className='bg-[#0072a4] p-6 rounded-lg shadow'>
          <h2 className='text-2xl text-white mb-4'>Modifier un produit</h2>
          <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleUpdateProduct}>
            <input
              type='text'
              name='name'
              placeholder='Nom du produit'
              value={formData.name}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='price'
              step='0.01'
              placeholder='Prix'
              value={formData.price}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <textarea
              name='description'
              placeholder='Description'
              value={formData.description}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='quantity'
              step='0.01'
              placeholder='Quantité'
              value={formData.quantity}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <select
              name='type'
              value={formData.type}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%] text-gray-400'
            >
              <option value='' disabled>Sélectionnez le type</option>
              <option value='single'>unique</option>
              <option value='packet'>Pack</option>
            </select>
            <input
              type='text'
              name='category'
              placeholder='Categorie'
              value={formData.category}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='number'
              name='sale'
              placeholder='Promotion'
              value={formData.sale}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='text'
              name='marque'
              placeholder='Marque'
              value={formData.marque}
              onChange={handleInputChange}
              className='p-2 rounded-lg w-[90%]'
            />
            <input
              type='file'
              name='img'
              onChange={handleFileChange}
              className='p-2 rounded-lg w-[90%]'
              multiple
            />
            <button className='button bg-white text-[#0072a4] w-1/2 border-white rounded'>Modifier</button>
          </form>
        </div>
      )}

      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
        {products.map((product) => (
          <div key={product.idpoducts} className='bg-white p-4 rounded-lg shadow'>
          
            {/* Displaying images */}
            <div className='flex flex-wrap gap-2 mt-4'>
              
            <img src={JSON.parse(product.images)[0]} className='w-full border rounded-lg shadow' alt="" />
    
            </div>
            <h3 className='text-xl mb-2'>{product.name}</h3>
            <p className='text-gray-600'>{product.price}DT</p>
            <p className='text-gray-600 truncate'>{product.description}</p>
            <div className='flex justify-between mt-4'>
              <button
                onClick={() => handleEditProduct(product.idpoducts)}
                className='button text-blue-500'
              >
                Modifier
              </button>
              <button
                onClick={() => handleDeleteProduct(product.idpoducts)}
                className='text-red-500'
              >
                <MdDeleteOutline size={24} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Product;
