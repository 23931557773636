import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/try for navbar/HomeTest.jsx'
import Blogs from './components/Blogs/Blogs.jsx';
import Brands from './components/Brands.jsx';
import ContactUs from './components/ContactUs.jsx';
import Dashboard from './components/admin/Dashboard.jsx'
import Products from './components/shop/Products.jsx';
import Register from './components/register/Register.jsx';
import Try from './components/try for navbar/HomeTest.jsx'
import About from './components/AboutUs.jsx'
import Details from './components/product/ProductDetail.jsx';
import AllBlogs from './components/AllBlogs.jsx';
import OneBlog from './components/OneBlog.jsx';
import Succes from './components/checkout/Succes.jsx';
import Error from './components/checkout/Error.jsx';
import CartList from './components/CartList.jsx';
import ProductDisplay from './components/ProductsDisplay.jsx';
import Checkout from './components/checkout/Checkout.jsx';
import Formulair from './components/checkout/Formulaire.jsx'
import WhishList from './components/WhishList.jsx';
import Navbar from './components/Navbar.jsx';
import Div from './components/div.jsx';
import Footer from './components/Footer.jsx';
import Login from './components/admin/Login.jsx';
import FAQ from './components/FAQ.jsx'
import './index.css'
function App() {
  return (
    <Routes>
        
     
        <Route path='/' element={<><Navbar/>
      <Div/><Home/> <Footer/></>} />
       
       
    
        <Route path='/Blogs' element={<> <Navbar/>
      <Div/><Blogs/> <Footer/></>} />
       
       
        <Route path='/Brands' element={<> <Navbar/>
      <Div/><Brands/><Footer/></>} />
     
    
        <Route path='/About' element={<> <Navbar/>
      <Div/><About/> <Footer/></>} />
    
     
        <Route path='/Contact' element={<><Navbar/>
      <Div/><ContactUs/> <Footer/></>} /> 
    
        <Route path='/spiraNature/admin/dashboard' element={<Dashboard/>} /> 
        <Route path='/admin/login' element={<Login/>} /> 
        
        <Route path='/shop' element={<> <Navbar/>
      <Div/><Products/> <Footer/></>} /> 
    
        <Route path='/register' element={<> <Navbar/>
      <Div/><Register/><Footer/></>} /> 
        <Route path='/try' element={<> <Navbar/>
      <Div/><Try/><Footer/></>} /> 
        <Route path='/details/:id' element={<> <Navbar/>
      <Div/><Details/><Footer/></>} /> 
        <Route path='/allblogs' element={<> <Navbar/>
      <Div/><AllBlogs/><Footer/></>} /> 
        <Route path='/oneblog/:id' element={<> <Navbar/>
      <Div/><OneBlog/><Footer/></>} /> 
        <Route path='/success' element={<> <Navbar/>
      <Div/><Succes/><Footer/></>} /> 
        <Route path='/cancel' element={<> <Navbar/>
      <Div/><Error/><Footer/></>} /> 
        <Route path='/cart' element={<> <Navbar/>
      <Div/><CartList/><Footer/></>} /> 
        <Route path='/display' element={<> <Navbar/>
      <Div/><ProductDisplay/><Footer/></>} /> 
        <Route path='/Checkout' element={<> <Navbar/>
      <Div/><Checkout/><Footer/></>} />
        <Route path='/Formulaire' element={<> <Navbar/>
      <Div/><Formulair/><Footer/></>} />
        <Route path='/liste-de-souhaits' element={<> <Navbar/>
      <Div/><WhishList/><Footer/></>} />
      <Route path='/FAQ' element={<><Navbar/>
      <Div/><FAQ/><Footer/></>} />
    </Routes>
  );
}

export default App;